import React from "react"

const ParamsTable = ({ params }) => {
  return params.length ? (
    <table>
      <caption className="sr-only">uri parameters</caption>
      <thead>
        <tr>
          <th>Name</th>
          <th>Required</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {params.map((key, i) => (
          <tr key={i}>
            <td>{key.name}</td>
            <td>{key.required}</td>
            <td>{key.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : null
}

export default ParamsTable
