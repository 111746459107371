import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import SchemaTable from "../components/schemaTable/SchemaTable";
import ParamsTable from "../components/paramsTable/ParamsTable";
import RedBox from "../components/redBox/RedBox";
import Seo from "../components/seo";
import dets from "../../dets.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title="gRoute" mdxType="Seo" />
    <h1>{`GRoute`}</h1>
    <p>{`GTFS routes.txt file are groups "of trips that are displayed to riders as a single service", there are 10 routes relating to Galway City - one each for 401, 402, 404, 405, 407, 409, 410, 411, 412, 414. Instead GRoutes are based on `}<inlineCode parentName="p">{`route_short_name`}</inlineCode>{` - `}<inlineCode parentName="p">{`direction_id`}</inlineCode>{` combinations so there are 20. This means they can also hold information about associated bus stops.`}</p>
    <h2 id="g-route-schema">GRoute Schema</h2>
    <SchemaTable dets={dets.gRoute} mdxType="SchemaTable" />
    <RedBox mdxType="RedBox">
      <h4>Note</h4>
      <p>Bus Éireann's GTFS files use at least two <code>route_id</code>s to refer to the same routes. eg 10-401-e19-1 and 10-401-e20-1 both refer to the route with <code>route_short_name</code> 401.</p>
      <p>This project disregards <code>route_id</code> s and instead relies on a combination of <code>route_short_name</code> and <code>direction_id</code> for all queries. For convenience the long <code>route_id</code>s are accepted in uris. <code>/api/groutes/10-405-e19-1</code> will get the same results as <code>/api/groutes/405</code></p>
    </RedBox>
    <section>
      <h2 id={dets.gRoute.routes[0].id}>{dets.gRoute.routes[0].title}</h2>
      <p>{`Gets all 20 Galway City GRoutes.`}</p>
      <h3>{`Example`}</h3>
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`/api/groute/
`}</code></pre>
  <details>
    <summary>
    <h4>Response</h4>
    </summary>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "results": [
    {
      "route_short_name": "401",
      "direction_id": "0",
      "route_long_name": "Parkmore Industrial Estate - Dr Mannix Rd (Rockbarton Rd)",
      "g_trip_headsign": "Parkmore Industrial Estate - Dr Mannix Rd (Rockbarton Rd)",
      "g_route_variations": [
        {
          "route_id": "10-401-e19-1",
          "route_short_name": "401",
          "direction_id": "0",
          "first_stop_id": "8460B6355601",
          "last_stop_id": "8460B522301",
          "first_stop_name": "Galway Tech Park, stop 635561",
          "last_stop_name": "Scoil Éinde Boys NS, stop 522301",
          "num_stops": 37
        },
        {
          "route_id": "10-401-e19-1",
          "route_short_name": "401",
          "direction_id": "0",
          "first_stop_id": "8460B6355601",
          "last_stop_id": "8460B522331",
          "first_stop_name": "Galway Tech Park, stop 635561",
          "last_stop_name": "Eyre Square, stop 522331",
          "num_stops": 24
        }
      ],
      "g_stops": [
        {
          "stop_id": "8460B6355601",
          "stop_name": "Galway Tech Park, stop 635561",
          "stop_lat": 53.29902494788,
          "stop_lon": -8.98686582677608
        }
        // ... 36 more g_stops on route (ordered).
      ],
      "g_route_ids": ["10-401-e19-1", "10-401-e20-1"]
    }
    // ... 19 more g_routes
  ]
}
`}</code></pre>
  </details>
    </section>
    <section className="minHeightSection">
      <h2 id="g-route-by-routeid-directionid">{dets.gRoute.routes[1].title}</h2>
      <h3>{`Params`}</h3>
      <ParamsTable params={dets.gRoute.routes[1].params} mdxType="ParamsTable" />
      <h3>{`Example`}</h3>
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`/api/groute/401/1
`}</code></pre>
  <details>
    <summary>
    <h4>Response</h4>
    </summary>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "results": [
    {
      "route_id": "10-401-e19-1",
      "route_short_name": "401",
      "direction_id": "1",
      "g_route_ids": ["10-401-e19-1", "10-401-e20-1"],
      "route_long_name": "Parkmore Industrial Estate - Dr Mannix Rd (Rockbarton Rd)",
      "g_trip_headsign": "Dr Mannix Rd (Rockbarton Rd) - Parkmore Industrial Estate",
      "g_route_variations": [
        // ... g_route_variations
      ],
      "g_stops": [
        // ... g_stops on route (ordered)
      ]
    }
  ]
}
`}</code></pre>
  </details>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      