import React from "react"
import * as classes from "./redBox.module.scss"

const RedBox = ({ children }) => {
  return <div className={classes.redBox}>
		
		<>{children}</>
		</div>
}

export default RedBox
