import React from "react"
import * as classes from "./schemaTable.module.scss"

const SchemaTable = ({ dets }) => {
  return (
    <table>
      <caption className="sr-only">{dets.name} Schema</caption>
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Description</th>
          <th>From GTFS .txt file</th>
        </tr>
      </thead>
      <tbody>
        {dets && dets.schema ? dets.schema.map((key, i) => (
          <tr key={i} className={key.og ? classes.og : classes.notOg}>
            <td>{key.key}</td>
            <td>{key.type}</td>
            <td>{key.description}</td>
            <td>{key.og ? "yes" : "no"}</td>
          </tr>
        )) : null}
      </tbody>
    </table>
  )
}

export default SchemaTable
